import { AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { environment } from '@environments/environment';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { NavigationService } from '@services/navigation-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { SubSink } from 'subsink';
import { manipulateCrewData } from '../related-content';
import { CREW_API_FILTERS, CREW_COLUMNS, CREW_DEFAULT_TABLE_PARAMS, CREW_INNER_COLUMNS, CREW_REQUEST_STATUS_DETAILS, CREW_STATUS_FILTERS, CREW_TYPE_FILTERS, TableColumns } from '../related-content-utils';

type NBCApp = 'guest-tracker' | 'ncx' | 'news-search' | 'producer-dashboard';

@Component({
  selector: 'crew-related-content',
  templateUrl: './crew-related-content.component.html',
  styleUrls: ['../story-related-content.component.scss', './crew-related-content.component.scss'],
})
export class CrewRelatedContentComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {

  // Story information
  @Input() storyId: number = 0;

  @Input() relatedContentHeight: number = 0;

  @Input() relatedContentTop: number = 0;

  @Input() showTempModal: boolean = false;

  @Output() totalRecords: EventEmitter<number> = new EventEmitter<number>();

  isLoaded: boolean = true;

  innerRelatedContentData: any = [];

  relatedContentData: any = [];

  headerHeight: number = 0;

  tableHeight: number = 0;

  heightToBeRemoved: number = 0;

  preferenceColumns: { [key: string]: TableColumns } = {};

  innerTablePreferenceColumns: { [key: string]: TableColumns } = {};

  columns: any;

  innerTableColumns: any;

  private subs = new SubSink();

  dateSortOrder: string = null;
  submittedDateSortOrder: string = null;
  apiQueryFilters: any;
  typeFilters: any;
  tableParams: any;
  statusFilters: any;
  requestStatusDetails: any;

  idSearchVisible: boolean = false;
  idSearchText: string = '';

  locationSearchVisible: boolean = false;
  locationSearchText: string = '';
  priorLocationSearchText: string = '';
  locationPopoverVisible: boolean = false;

  requesterSearchVisible: boolean = false;
  requesterSearchText: string = '';
  priorRequesterSearchText: string = '';

  showOrProjectSearchVisible: boolean = false;
  showOrProjectSearchText: string = '';
  priorShowOrProjectSearchText: string = '';

  fieldContactSearchVisible: boolean = false;
  fieldContactSearchText: string = '';
  priorFieldContactSearchText: string = '';

  phoneNumberSearchVisible: boolean = false;
  phoneNumberSearchText: string = '';
  priorPhoneNumberSearchText: string = '';

  expandRecord: boolean = true;

  searchText: Subject<string> = new Subject();

  resetStatusButton: boolean = false;
  requestStatusVisible: boolean = false;
  requestStatusTypeFilterStatus: boolean = false;

  private destroy$ = new Subject();

  constructor(
    private breakpointService: BreakpointService,
    private cService: CommonService,
    private toastService: ToastService,
    private gaService: GoogleAnalyticsEventService,
    private navigationService: NavigationService
  ) { }

  ngOnInit() {

    this.searchText.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe(() => {

      this.searchId();
    });

    this.typeFilters = JSON.parse(JSON.stringify(CREW_TYPE_FILTERS));

    this.apiQueryFilters = JSON.parse(JSON.stringify(CREW_API_FILTERS));

    this.tableParams = JSON.parse(JSON.stringify(CREW_DEFAULT_TABLE_PARAMS));

    this.statusFilters = JSON.parse(JSON.stringify(CREW_STATUS_FILTERS));

    this.requestStatusDetails = JSON.parse(JSON.stringify(CREW_REQUEST_STATUS_DETAILS));

    this.loadColumns();

    // this.totalRecords.emit(0);

    if (this.storyId) {

      this.loadRelatedContent(true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.storyId && changes.storyId.currentValue) {

      // this.loadRelatedContent();

    }

  }

  ngAfterViewChecked() {

    // this.windowResizeEvent();

  }


  /**
   * Resize event to calculate the height of the inner body to enable scroll
   **/
  public windowResizeEvent() {

    // this.heightToBeRemoved = (16 * 3) + (24 * 2) + (40 * 2);

    // this.tableHeight = this.relatedContentHeight - this.heightToBeRemoved;

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

    this.destroy$.complete();

  }

  /**
   * Make API call to get the related content for the story
   *
   */
  loadRelatedContent(emitTotalRecords: boolean = false) {

    this.isLoaded = false;

    this.apiQueryFilters.storyId = this.storyId;// 1733260492448;// 1724947933414;

    let apiResponse = this.cService.serviceRequestCommon('post', environment.crewRequestAPI, '', this.apiQueryFilters);

    apiResponse.subscribe((response: any) => {

      let results = response?.results || [];

      if (Array.isArray(results)) {

        manipulateCrewData(response?.results);

        this.relatedContentData = [...results];

        this.innerRelatedContentData = [...results];

        // console.log('related Content', { relatedContent: this.data, response });

        this.tableParams.total = response.total || 0;

        if (emitTotalRecords) {
          this.totalRecords.emit(this.tableParams.total);
        }
      }

    }, (error: any) => {

      console.error('Load Related Content', error);

      this.toastService.createMessage('error', 'Error while loading related content. Please try again.');

    }).add(() => {

      this.isLoaded = true;

    });

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }


  /**
    * Changing pages
    * @param page {number}
    *
    */
  onChangePage(page: number) {

    this.tableParams.page = page;
    this.apiQueryFilters.start = ((page - 1) * this.apiQueryFilters.count) + 1;

    this.loadRelatedContent();

  }

  /**
   * Changing number of items to display per page
   * @param limit {number}
   *
   */
  onChangePageSize(limit: number) {

    this.tableParams.limit = limit;
    this.apiQueryFilters.count = limit;

    // If Page size changed then we need to reset page index as 1 as well start also
    const page = 1;
    this.tableParams.page = page;
    this.apiQueryFilters.start = ((page - 1) * this.apiQueryFilters.count) + 1;

    this.loadRelatedContent();
  }

  navigationTo(id: string) {

  }



  loadColumns() {

    this.preferenceColumns = {};

    this.columns = JSON.parse(JSON.stringify(CREW_COLUMNS));;

    this.columns.forEach((column: TableColumns) => {
      this.preferenceColumns[column.value] = column;
    });

    this.innerTablePreferenceColumns = {};

    this.innerTableColumns = JSON.parse(JSON.stringify(CREW_INNER_COLUMNS));;

    this.innerTableColumns.forEach((column: TableColumns) => {
      this.innerTablePreferenceColumns[column.value] = column;
    });



  }

  typeFilterEvent(event: any) {

    this.resetTableParams();

    if (event?.length == 0)
      this.apiQueryFilters.requestType = ["General Crew Request", "Telemundo Crew", "CNBC Crew", "Digital Journalist"];
    else
      this.apiQueryFilters.requestType = event;

    this.loadRelatedContent();

  }

  statusFilterEvent(event: any) {

    this.resetTableParams();

    if (event?.length == 0)
      this.apiQueryFilters.status = ["NEW", "REVISED", "Efforting", "ROFR", "Booked"];
    else
      this.apiQueryFilters.status = event;

    this.loadRelatedContent();

  }

  startDateSortEvent(event: any) {

    if (event === null && this.apiQueryFilters.sortField !== 'startDate') return;
    this.resetTableParams();

    if (event == null) {
      this.apiQueryFilters.sortField = 'startDate';;
      this.apiQueryFilters.sortOrder = 'descending';
    } else {
      this.apiQueryFilters.sortField = 'startDate';
      this.apiQueryFilters.sortOrder = (event == 'ascend') ? 'ascending' : 'descending';
    }

    this.dateSortOrder = event;

    this.loadRelatedContent();
  }

  resetTableParams() {

    const currentTableParams = JSON.parse(JSON.stringify(this.tableParams));

    let defaultTableParams = JSON.parse(JSON.stringify(CREW_DEFAULT_TABLE_PARAMS));

    defaultTableParams.limit = currentTableParams.limit;

    this.tableParams = JSON.parse(JSON.stringify(defaultTableParams));

    this.apiQueryFilters.start = 1;

  }

  /** 
   * Search for id
  */
  search() {
    this.searchText.next(this.idSearchText);
  }

  searchId(): void {
    this.resetTableParams();
    this.idSearchVisible = false;
    if (this.idSearchText.length === 0) {
      this.apiQueryFilters.filter.id = '*';
    } else {
      this.apiQueryFilters.filter.id = this.idSearchText + '*';
    }
    this.loadRelatedContent();
  }

  resetIdSearch(): void {
    this.idSearchVisible = false;
    this.idSearchText = '';
    this.apiQueryFilters.filter.id = '*';
    this.loadRelatedContent();
  }

  /** 
  * Search for a show
 */
  searchLocation(): void {
    this.resetTableParams();
    this.locationSearchVisible = false;
    if (this.locationSearchText.length === 0) {
      this.apiQueryFilters.location = '';
    } else {
      this.apiQueryFilters.location = this.locationSearchText + '*';
    }
    this.priorLocationSearchText = this.locationSearchText;
    this.loadRelatedContent();
  }

  resetLocationSearch(): void {
    this.locationSearchVisible = false;
    this.locationSearchText = '';
    this.apiQueryFilters.location = '';
    this.priorLocationSearchText = '';
    this.loadRelatedContent();
  }

  locationVisibleChange(event: boolean): void {
    if (!event && this.locationSearchText !== this.priorLocationSearchText) {
      this.searchLocation();
    }
  }


  /**
   * Search for a segment
   */
  searchRequester(): void {
    this.resetTableParams();
    this.requesterSearchVisible = false;
    if (this.requesterSearchText.length === 0) {
      this.apiQueryFilters.requester = '';
    } else {
      this.apiQueryFilters.requester = this.requesterSearchText + '*';
    }
    this.priorRequesterSearchText = this.requesterSearchText;
    this.loadRelatedContent();
  }

  resetRequesterSearch(): void {
    this.requesterSearchVisible = false;
    this.requesterSearchText = '';
    this.apiQueryFilters.requester = '';
    this.priorRequesterSearchText = '';
    this.loadRelatedContent();
  }

  requesterVisibleChange(event: boolean): void {
    if (!event && this.requesterSearchText !== this.priorRequesterSearchText) {
      this.searchRequester();
    }
  }

  submittedDateSortEvent(event: any) {

    if (event === null && this.apiQueryFilters.sortField !== 'submittedDate') return;
    this.resetTableParams();

    if (event == null) {
      delete this.apiQueryFilters.sortField;
      delete this.apiQueryFilters.sortOrder;
    } else {
      this.apiQueryFilters.sortField = 'submittedDate';
      this.apiQueryFilters.sortOrder = (event == 'ascend') ? 'ascending' : 'descending';
    }

    this.submittedDateSortOrder = event;

    this.loadRelatedContent();
  }

  /** 
  * Search for a show
  */
  searchShowOrProject(): void {
    this.resetTableParams();
    this.showOrProjectSearchVisible = false;
    if (this.showOrProjectSearchText.length === 0) {
      this.apiQueryFilters.showUnit = [];
    } else {
      this.apiQueryFilters.showUnit = [this.showOrProjectSearchText + '*'];
    }
    this.priorShowOrProjectSearchText = this.showOrProjectSearchText;
    this.loadRelatedContent();
  }

  resetShowOrProjectSearch(): void {
    this.showOrProjectSearchVisible = false;
    this.showOrProjectSearchText = '';
    this.apiQueryFilters.showUnit = [];
    this.priorShowOrProjectSearchText = '';
    this.loadRelatedContent();
  }

  ShowOrProjectVisibleChange(event: boolean): void {
    if (!event && this.showOrProjectSearchText !== this.priorShowOrProjectSearchText) {
      this.searchShowOrProject();
    }
  }


  /**
 * Search for a Field Contact
 */
  searchFieldContact(): void {
    this.resetTableParams();
    this.fieldContactSearchVisible = false;
    if (this.fieldContactSearchText.length === 0) {
      this.apiQueryFilters.filter.fieldContactName = '';
    } else {
      this.apiQueryFilters.filter.fieldContactName = this.fieldContactSearchText + '*';
    }
    this.priorFieldContactSearchText = this.fieldContactSearchText;
    this.loadRelatedContent();
  }

  resetFieldContactSearch(): void {
    this.fieldContactSearchVisible = false;
    this.fieldContactSearchText = '';
    this.apiQueryFilters.filter.fieldContactName = '';
    this.priorFieldContactSearchText = '';
    this.loadRelatedContent();
  }

  fieldContactVisibleChange(event: boolean): void {
    if (!event && this.fieldContactSearchText !== this.priorFieldContactSearchText) {
      this.searchFieldContact();
    }
  }


  /**
* Search for a Field Contact
*/
  searchPhoneNumber(): void {
    this.resetTableParams();
    this.phoneNumberSearchVisible = false;
    if (this.phoneNumberSearchText.length === 0) {
      this.apiQueryFilters.filter.producerCellNumber = '';
    } else {
      this.apiQueryFilters.filter.producerCellNumber = this.phoneNumberSearchText + '*';
    }
    this.priorPhoneNumberSearchText = this.phoneNumberSearchText;
    this.loadRelatedContent();
  }

  resetPhoneNumberSearch(): void {
    this.phoneNumberSearchVisible = false;
    this.phoneNumberSearchText = '';
    this.apiQueryFilters.filter.producerCellNumber = '';
    this.priorPhoneNumberSearchText = '';
    this.loadRelatedContent();
  }

  phoneNumberVisibleChange(event: boolean): void {
    if (!event && this.phoneNumberSearchText !== this.priorPhoneNumberSearchText) {
      this.searchPhoneNumber();
    }
  }

  /**
  * Open selected ProdReq app for the environment
  *
  */
  openApp(url: string, key: NBCApp): boolean {

    if (this.navigationService.isIOSMobileApp.value) {
      return true;
    } else {
      const win = window.open();
      if (win) {
        win.opener = null;
        win.location.href = url;
        this.gaService.sendEvent('Story Landing - Related Content', `Open ${key}`, url, 1);
        return false;
      }
    }
  }

  windowResize() {
    // this.heightToBeRemoved = (32 + 100) + (16 * 3) + (24 * 2) + (40 * 2);

    // this.tableHeight = this.relatedContentHeight - this.heightToBeRemoved;

    // this.resizeColumnWidths();
  }



  isEllipsisApplied(element: HTMLElement): boolean {
    console.log(element.scrollWidth, element.clientWidth);
    return element.scrollWidth > element.clientWidth;
  }

  onMouseEnter(element: HTMLElement, content: any): void {
    // console.log(element.scrollWidth, element.clientWidth);
    content.ellipsis = element.scrollWidth > element.clientWidth;
  }

  onMouseLeave(content: any): void {
    content.ellipsis = false;
  }

  closeModal() {
    this.showTempModal = false;
  }


  visibleChangeEventRequestStatus(event: any) {
    // if(!event && this.resetStatusButton)
    if (!event) {
      this.requestStatusOkEvent();
    }
  }


  /**
  * Search column filter request status
  *
  * @param listOfSearchName
  * @param selectedItem
  */
  requestStatusTypeSearch(listOfSearchName: any, selectedItem: any) {
    selectedItem.byDefault = listOfSearchName;
    this.resetStatusButton = false;
    this.resetStatusButton = this.statusFilters.some(
      (x: any) => x.byDefault === true
    );
    if (this.resetStatusButton) return;
  }

  /**
* Reset selected request Status search column filter request
*
*/
  requestStatusResetEvent() {
    this.resetTableParams();
    this.resetStatusButton = false;
    this.requestStatusVisible = false;
    this.requestStatusTypeFilterStatus = false;
    let resetStatusValue: string[] = [];

    resetStatusValue = this.statusFilters.map((val: any) => {
      val.byDefault = false;
      return val.value;
    });
    this.apiQueryFilters.status = ["NEW", "REVISED", "Efforting", "ROFR", "Booked"];
    this.loadRelatedContent();
  }

  /**
 * Search column filter request
 *
 */
  requestStatusOkEvent() {
    this.resetTableParams();
    this.requestStatusVisible = false;
    let filterValue: any[] = [];
    this.requestStatusTypeFilterStatus = false;
    this.resetStatusButton = false;
    this.statusFilters.map((obj: any) => {
      if (obj.byDefault) {
        filterValue.push(obj.value);
        this.resetStatusButton = true;
        this.requestStatusTypeFilterStatus = true;
      }
    });
    if (filterValue && filterValue.length) {
      this.apiQueryFilters.status = filterValue;
    } else {
      this.apiQueryFilters.status = ["NEW", "REVISED", "Efforting", "ROFR", "Booked"];
    }
    this.loadRelatedContent();
  }
}


