<!-- RESULT COUNT -->
<div
   *ngIf="isLoaded || (total > 0 && !isLoaded)"
   class="result-count">
   {{ resultCount }}
   <button
      *ngIf="searchService.suggestedSearch.show"
      (click)="searchService.performSuggestedSearch()"
      class="suggested-search">
      Did you mean {{ searchService.suggestedSearch.keyword }}?
   </button>
</div>

<app-scroll-container>
   <ng-template bodyTemplate>
      <div
         [ngClass]="{ 'search-result-container': true, 'no-results': !searchResults.length }"
         id="scrollableContainer"
         infiniteScroll
         [infiniteScrollDistance]="scrollDistance"
         (scrolled)="onScrollDown()"
         [scrollWindow]="false"
         data-scrollable-container
         [attr.data-component]="'ncx/search/page/results/groups'">
         <div
            class="search-result-body"
            id="groupContainer">
            <!-- LOADER -->
            <app-loader-component
               loaderType="SECTION_LOADER"
               [isLoaded]="isLoaded"></app-loader-component>

            <!-- CARD TO DISPLAY GROUP SEARCH RESULTS -->
            <ncx-group-search
               *ngFor="let group of searchResults; let index = index"
               (viewGroup)="viewGroup(group, index)"
               [group]="group"></ncx-group-search>

            <div *ngIf="!searchResults.length && isLoaded">
               <nz-empty [nzNotFoundContent]="contentTpl">
                  <ng-template #contentTpl>
                     <span>No Groups Found</span>
                  </ng-template>
               </nz-empty>
            </div>
         </div>
      </div>
   </ng-template>
</app-scroll-container>
