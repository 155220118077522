export enum requestStatus {
    queue = 'QUEUE',
    new = 'NEW',
    inprogress = 'IN PROGRESS',
    revised = 'REVISED',
    completed = 'COMPLETED',
    confirmcancellation = 'CONFIRM CANCELLATION',
    cancelled = 'CANCELLED',
    fulfilled = 'FULFILLED'
};

export const GUEST_BOOKING_COLUMNS: any = [
    {
        text: 'Date',
        value: 'DATE',
        width: 112,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Time',
        value: 'TIME',
        width: 104,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'App',
        value: 'APP',
        width: 78,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Guest',
        value: 'GUEST',
        width: 280,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Show',
        value: 'SHOW',
        width: 345,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Segment',
        value: 'SEGMENT',
        width: 349,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },

];

export const GUEST_BOOKING_API_FILTERS = {

    "filter": {
        "app": "excludeCancelIfNotRecurring",  //'recurring'  or 'cancelled' or 'all'
        "topic": "",  // for seach text of Segment column
        "showName": "", //search text of show name column
        "guestName": "",  // search text of guest name column
        "storyID": "", // Id of the story
        "autoGeneratedStoryId": ""  // Autogenerated story Id of the corresponding story, if present. else empty string
    },
    "sort": {
        "field": "onAirDate",
        "fieldType": "xs:long",
        "order": "descending"  // 'descending' or 'ascending'
    },
    "page": {
        "startIndex": 1,
        "size": 10
    }

}

// export const GUEST_BOOKING_APP_FILTERS = [
//     { text: 'All', value: 'excludeCancelIfNotRecurring', byDefault: false },
//     { text: 'Recurring', value: 'recurring', byDefault: false },
// ];

export const GUEST_BOOKING_APP_FILTERS = [
    { title: 'All', value: 'excludeCancelIfNotRecurring', key: 'all', },
    { title: 'Recurring', value: 'recurring', key: 'recurring' }
];

export enum GUEST_BOOKING_METRICS {
    'ALL' = 'All',
    'BOOKINGS' = 'Bookings',
    // 'CALLOUTS' = 'Callouts',
    'APPEARANCES' = 'Appearances'
}

export const GUEST_BOOKING_DEFAULT_TABLE_PARAMS = {
    page: 1,
    total: 10,
    limit: 10,
};

export const CREW_TYPE_FILTERS = [
    { text: 'General Crew Request', value: 'General Crew Request', byDefault: false },
    { text: 'CNBC Crew', value: 'CNBC Crew', byDefault: false },
    { text: 'Telemundo Crew', value: 'Telemundo Crew', byDefault: false },
    { text: 'Digital Journalist / DJ Shoot Crew', value: 'Digital Journalist', byDefault: false },
];

export const CREW_STATUS_FILTERS = [
    { text: 'New', value: 'New', byDefault: false },
    { text: 'Revised', value: 'Revised', byDefault: false },
    { text: 'Efforting', value: 'Efforting', byDefault: false },
    { text: 'ROFR', value: 'ROFR', byDefault: false },
    { text: 'Booked', value: 'Booked', byDefault: false },
];

export const CREW_COLUMNS: any = [
    {
        text: 'Type',
        value: 'TYPE',
        width: 180,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'ID / Status',
        value: 'IDSTATUS',
        width: 207,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Start',
        value: 'STARTDATE',
        width: 120,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Location',
        value: 'LOCATION',
        width: 345,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Crew Count',
        value: 'CREWCOUNT',
        width: 128,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Requester',
        value: 'REQUESTER',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Show / Project',
        value: 'SHOWPROJECT',
        width: 345,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Field Contact',
        value: 'FIELDCONTACT',
        width: 280,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Phone Number',
        value: 'PHONENUMBER',
        width: 280,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },

];

export const CREW_INNER_COLUMNS: any = [
    {
        text: 'Position',
        value: 'POSITION',
        width: 180,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Name',
        value: 'NAME',
        width: 196,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Email',
        value: 'EMAIL',
        width: 120,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Phone Number',
        value: 'PHONENUMBER',
        width: 280,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    }
];

export const CREW_API_FILTERS = {
    "filter": {
        "id": "*"
    },
    "status": [ // If search is needed based on crew request status, (all fields are mandatory if user doesn't select anything)
        "NEW",
        "REVISED",
        "EFFORTING",
        "ROFR",
        "BOOKED"
    ],
    "requestType": [ // If search is needed based on crew request type, (all fields are mandatory if user doesn't select anything)
        "General Crew Request",
        "Telemundo Crew",
        "CNBC Crew",
        "Digital Journalist"
    ],
    "requestId": "", // If search is needed based on edit request id
    "location": "", // If search is needed based on location (* is mandatory after the search word)
    "daterange": {},
    "showUnit": [], // If search is needed based on show unit
    "slugValue": "", // If search is needed based on slug value
    "search": "",
    "start": 1, // starting index
    "count": 10, // how many records need to be fetched per request
    "requester": "", // if search is needed based on requester name (Full name has to be given)
    "sortField": "startDate", //sort field is startDate for crew
    "sortOrder": "descending", //ascending or descending
    "loginName": "",
    "storyId": 0, // mandatory field for ncx
    "storyRequest": true // mandatory field for ncx
}

export const CREW_REQUEST_STATUS_DETAILS: any = {
    'NEW': { name: requestStatus.new, index: 1, color: '#EB2F96', class: 'new', icon: 'star' },
    'Efforting': { name: requestStatus.inprogress, index: 2, color: '#1890FF', class: 'in-progress', icon: 'sync' },
    'ROFR': { name: requestStatus.inprogress, index: 2, color: '#1890FF', class: 'in-progress', icon: 'sync' },
    'REVISED': { name: requestStatus.revised, index: 3, color: '#FA8C16', class: 'revised', icon: 'exclamation-circle' },
    'Booked': { name: requestStatus.completed, index: 4, color: '#52C41A', class: 'completed', icon: 'check-circle' },
};

export const CREW_DEFAULT_TABLE_PARAMS = {
    page: 1,
    total: 10,
    limit: 10,
};

export const EDIT_TYPE_FILTERS = [
    { text: 'Standard', value: 'Edit Request', byDefault: false },
    { text: 'Long Form', value: 'Long Form Edit Request', byDefault: false },
];

export const EDIT_AIR_DATE_FILTERS = [
    { text: 'TBD', value: 'TBD', byDefault: false },
];

export const EDIT_STATUS_FILTERS = [
    { text: 'New', value: 'NEW', byDefault: false },
    { text: 'Revised', value: 'REVISED', byDefault: false },
    { text: 'Fulfilled', value: 'FULFILLED', byDefault: false },
];

export const EDIT_COLUMNS: any = [
    {
        text: 'Type',
        value: 'TYPE',
        width: 218,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'ID / Status',
        value: 'IDSTATUS',
        width: 220,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Air Date',
        value: 'AIRDATE',
        width: 120,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Requester',
        value: 'REQUESTER',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Show / Project',
        value: 'SHOWPROJECT',
        width: 345,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Producer',
        value: 'PRODUCER',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Senior Producer',
        value: 'SRPRODUCER',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    }
];

export const EDIT_API_FILTERS = {
    "filter": { "id": "*" },
    "status": [
        "NEW",
        "REVISED",
        "FULFILLED"
    ],
    "requestType": [
        "Edit Request",
        "Long Form Edit Request"
    ],
    "requestId": "",
    "daterange": {},
    "showUnit": [],
    "slugValue": "",
    "search": "",
    "start": 1,
    "count": 10,
    "sortField": "airDate",
    "sortOrder": "descending",
    "loginName": "",
    "storyId": 0,
    "storyRequest": true,
    "requester": "",
    "SeniorProducer": "",
    "Producer": "",
    "location": ""
}

export const EDIT_REQUEST_STATUS_DETAILS: any = {
    'QUEUE': { name: requestStatus.queue, index: 0, color: '#D4380D', class: 'queue', icon: 'close-circle' },
    'NEW': { name: requestStatus.new, index: 1, color: '#EB2F96', class: 'new', icon: 'star' },
    'IN PROGRESS': { name: requestStatus.inprogress, index: 2, color: '#1890FF', class: 'in-progress', icon: 'sync' },
    'REVISED': { name: requestStatus.revised, index: 3, color: '#FA8C16', class: 'revised', icon: 'exclamation-circle' },
    'FULFILLED': { name: requestStatus.completed, index: 4, color: '#52C41A', class: 'completed', icon: 'check-circle' },
    'COMPLETED': { name: requestStatus.completed, index: 4, color: '#52C41A', class: 'completed', icon: 'check-circle' },
    'CONFIRM CANCELLATION': { name: requestStatus.confirmcancellation, index: 5, color: '#808080', class: 'confirm-cancellation', icon: 'close-circle' },
    'CANCELLED': { name: requestStatus.cancelled, index: 6, color: '#000000', class: 'cancelled', icon: 'close-circle' }
};

export const EDIT_DEFAULT_TABLE_PARAMS = {
    page: 1,
    total: 10,
    limit: 10,
};




export const FILE_INGEST_STATUS_FILTERS = [
    { text: 'New', value: 'New', byDefault: false },
    { text: 'Revised', value: 'Revised', byDefault: false },
    { text: 'In Progress', value: 'In Progress', byDefault: false },
    { text: 'Completed', value: 'Completed', byDefault: false },
];

export const FILE_INGEST_COLUMNS: any = [
    {
        text: 'ID / Status',
        value: 'IDSTATUS',
        width: 199,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Destination',
        value: 'DESTINATION',
        width: 345,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Requester',
        value: 'REQUESTER',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Submitted',
        value: 'SUBMITTED',
        width: 188,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Modified',
        value: 'MODIFIED',
        width: 148,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Show / Project',
        value: 'SHOWPROJECT',
        width: 345,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Air Date',
        value: 'AIRDATE',
        width: 120,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
];

export const FILE_INGEST_API_FILTERS = {
    "filter": { "id": "*" },
    "status": [
        "NEW",
        "REVISED",
        "IN PROGRESS",
        "COMPLETED",
    ],
    "requestType": [
        "File Ingest"
    ],
    "requestId": "",
    "daterange": {},
    "showUnit": [],
    "slugValue": "",
    "search": "",
    "start": 1,
    "count": 10,
    "sortField": "created",
    "sortOrder": "descending",
    "loginName": "",
    "storyId": 0,
    "storyRequest": true,
    "requester": "",
    "SeniorProducer": "",
    "Producer": "",
    "location": ""
}
export const FILE_INGEST_REQUEST_STATUS_DETAILS: any = {
    '0': { name: requestStatus.queue, index: 0, color: '#D4380D', class: 'queue', icon: 'close-circle' },
    '1': { name: requestStatus.new, index: 1, color: '#EB2F96', class: 'new', icon: 'star' },
    '2': { name: requestStatus.inprogress, index: 2, color: '#1890FF', class: 'in-progress', icon: 'sync' },
    '3': { name: requestStatus.revised, index: 3, color: '#FA8C16', class: 'revised', icon: 'exclamation-circle' },
    '4': { name: requestStatus.completed, index: 4, color: '#52C41A', class: 'completed', icon: 'check-circle' },
    '5': { name: requestStatus.confirmcancellation, index: 5, color: '#808080', class: 'confirm-cancellation', icon: 'close-circle' },
    '6': { name: requestStatus.cancelled, index: 6, color: '#000000', class: 'cancelled', icon: 'close-circle' }
};

export const FILE_INGEST_DEFAULT_TABLE_PARAMS = {
    page: 1,
    total: 10,
    limit: 10,
};




export const FEEDOUT_AIR_DATE_FILTERS = [
    { text: 'TBD', value: 'TBD', byDefault: false },
];

export const FEEDOUT_STATUS_FILTERS = [
    { text: 'New', value: 'New', byDefault: false },
    { text: 'Revised', value: 'Revised', byDefault: false },
    { text: 'In Progress', value: 'In Progress', byDefault: false },
    { text: 'Completed', value: 'Completed', byDefault: false },
];

export const FEEDOUT_COLUMNS: any = [
    {
        text: 'ID / Status',
        value: 'IDSTATUS',
        width: 206,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Submitted',
        value: 'SUBMITTED',
        width: 188,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Destination',
        value: 'DESTINATION',
        width: 345,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Media Id',
        value: 'MEDIAID',
        width: 224,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Requester',
        value: 'REQUESTER',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Show / Project',
        value: 'SHOWPROJECT',
        width: 345,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    }
];

export const FEEDOUT_API_FILTERS = {
    "filter": { "id": "*" },
    "status": [
        "NEW",
        "REVISED",
        "IN PROGRESS",
        "COMPLETED",
    ],
    "requestType": [
        "Feed Out"
    ],
    "requestId": "",
    "daterange": {},
    "showUnit": [],
    "slugValue": "",
    "search": "",
    "start": 1,
    "count": 10,
    "sortField": "created",
    "sortOrder": "descending",
    "loginName": "",
    "storyId": 0,
    "storyRequest": true,
    "requester": "",
    "SeniorProducer": "",
    "Producer": "",
    "location": ""
}

export const FEEDOUT_REQUEST_STATUS_DETAILS: any = {
    'QUEUE': { name: requestStatus.queue, index: 0, color: '#D4380D', class: 'queue', icon: 'close-circle' },
    'NEW': { name: requestStatus.new, index: 1, color: '#EB2F96', class: 'new', icon: 'star' },
    'IN PROGRESS': { name: requestStatus.inprogress, index: 2, color: '#1890FF', class: 'in-progress', icon: 'sync' },
    'REVISED': { name: requestStatus.revised, index: 3, color: '#FA8C16', class: 'revised', icon: 'exclamation-circle' },
    'COMPLETED': { name: requestStatus.completed, index: 4, color: '#52C41A', class: 'completed', icon: 'check-circle' },
    'CONFIRM CANCELLATION': { name: requestStatus.confirmcancellation, index: 5, color: '#808080', class: 'confirm-cancellation', icon: 'close-circle' },
    'CANCELLED': { name: requestStatus.cancelled, index: 6, color: '#000000', class: 'cancelled', icon: 'close-circle' }
};

export const FEEDOUT_DEFAULT_TABLE_PARAMS = {
    page: 1,
    total: 10,
    limit: 10,
};


export interface TableColumns {
    text: string,
    value: string,
    width: number,
    checked: boolean,
    rowSpan: number,
    colSpan: number,
    superHeader: string,
    extraSpan: boolean,
    reporting: boolean,
    firstLevelHeader: boolean
}

