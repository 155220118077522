<!-- STORY INFORMATION -->
<div
   class="ncx-story-header"
   data-component="shared/cards/story/search">
   <div
      *ngIf="story.autoGeneratedStoryId"
      class="story-id">
      {{ story.autoGeneratedStoryId }}
      <i
         class="copy-icon"
         nz-icon
         nzType="copy"
         nzTheme="outline"
         (click)="copyStoryIdInClipboard(story.autoGeneratedStoryId)"></i>
   </div>
   <a
      class="title"
      [href]="story.storyUrl"
      (click)="onViewStory()"
      nz-tooltip
      nzTooltipPlacement="bottomLeft"
      [nzTooltipMouseEnterDelay]="0.5"
      [nzTooltipTitle]="story.storyTitle"
      [innerHTML]="(story.snippet.title || story.storyTitle || '').trim() | safeHtml"></a>
</div>

<!-- AUTHOR -->

<!-- <div class="created-user">
   <span>Created By:</span>
   <span
      class="user-name"
      nz-popover
      [nzPopoverContent]="profileCardTemplate1"
      [nzPopoverPlacement]="'topLeft'">
      {{ authorName }}
   </span>
   <ng-template #profileCardTemplate1>
      <app-profile-overlay
         [profileData]="{ userId: story.userId, displayName: story.authorDisplayName }"></app-profile-overlay>
   </ng-template>
   <span>
      on {{ date | utcToLocal: 'DATE' }} at
      {{ date | utcToLocal: 'TIME' }}
   </span>
</div> -->

<div class="author">
   <button
      class="name"
      nz-popover
      [nzPopoverContent]="profileCardPerson"
      nzPopoverPlacement="topLeft">
      {{ authorName }}
   </button>

   <span class="time">&nbsp;{{ date }}</span>

   <ng-template #profileCardPerson>
      <app-profile-overlay
         [profileData]="{ userId: story.userId, displayName: story.authorDisplayName }"></app-profile-overlay>
   </ng-template>
</div>

<!-- FOOTER -->
<div class="ncx-story-footer">
   <div class="left-side">
      <!-- STORY TAGS -->
      <app-info-tags
         class="item"
         [total]="story.storyContentTags?.length || 0"></app-info-tags>
   </div>

   <div class="right-side"></div>
</div>
